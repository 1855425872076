export default function AboutMe() {
	return (
		<section id="AboutMe" className="about--section">
			<div className="about--section--img">
				<img src="./img/about-me.png" alt="About Me" />
			</div>
			<div className="hero--section--content--box about--section--box">
				<div className="hero--section--content">
					<h1 className="skills-section--heading">About Me</h1>
					<p className="about--section-description">
						Hello, I'm Jacob! Currently, I'm a Full-Stack Developer at Easy
						Automation, Inc. I bring a blend of technical expertise and creative
						problem-solving to every project. With a background in Computer
						Science and years of experience in web design and digital marketing,
						I thrive at the intersection of design, functionality, and
						innovation.
						<br />
						<br />
						My passion for coding and continuous learning drives me to stay
						ahead in both front-end and back-end technologies. I'm dedicated to
						building efficient, user-friendly solutions while optimizing
						processes to enhance both team performance and client satisfaction.
						<br></br>
						But my passion doesn't stop when the workday ends.
						<br />
						<br />
						Off the clock, you'll find me chasing the perfect shot with my
						camera, tackling rugged trails on my mountain bike, working on
						personal projects to further my knowledge and improve my skills,
						shredding the snow-covered hills, or cruising the open road on my
						motorcycle. I believe in blending work and play to create a balanced
						and fulfilling life.
					</p>
				</div>
			</div>
		</section>
	);
}
